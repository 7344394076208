<template>
    <div class="md:text-sm md:w-24 lg:w-32 xl:w-28 border-b-2 cursor-default" tabindex="1"
        :class="props.fullWidth === true ? 'w-14 text-xs' : 'w-20'"
        v-tooltip.bottom="{
              value: `${props.title.description}`,
              pt: {
                root: ({ context }) => ({
                class: [
                    'absolute shadow-md',
                    {
                        'py-0 px-1': context?.right || context?.left || (!context?.right && !context?.left && !context?.top && !context?.bottom),
                        'py-1 px-0': context?.top || context?.bottom
                    }
                ]
            }),
            arrow: ({ context }) => ({
                class: [
                    'absolute w-0 h-0 border-transparent border-solid',
                    {
                        '-m-t-1 border-y-[0.25rem] border-r-[0.25rem] border-l-0 border-r-stone-600': context?.right || (!context?.right && !context?.left && !context?.top && !context?.bottom),
                        '-m-t-1 border-y-[0.25rem] border-l-[0.25rem] border-r-0 border-l-stone-600': context?.left,
                        '-m-l-1 border-x-[0.25rem] border-t-[0.25rem] border-b-0 border-t-stone-600': context?.top,
                        '-m-l-1 border-x-[0.25rem] border-b-[0.25rem] border-t-0 border-b-stone-600': context?.bottom
                    }
                ]
            }),
            text: {
                class: 'text-sm p-2 bg-stone-600 text-white rounded-md whitespace-pre-line break-words border-2 border-amber-300/60'
            }
            }
          }">{{ props.title.name }}</div>
</template>
<script setup>
// eslint-disable-next-line no-undef
const props = defineProps({
    title: Object,
    fullWidth: Boolean,
})
</script>