import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from "primevue/config";
import 'primevue/resources/themes/lara-light-green/theme.css'
import '@/assets/style.css'
import Tooltip from 'primevue/tooltip'
import 'primeicons/primeicons.css'
import 'animate.css'
import VueLazyLoad from 'vue3-lazyload'

createApp(App).directive('tooltip', Tooltip).use(PrimeVue, {unstyled: true}).use(VueLazyLoad).mount('#app')