import axios from "axios";

// Local
// const api = "http://192.168.0.32:3000/api/updates";

// Deploy test
// const api = 'https://starraidle-api.onrender.com/api/updates';

// DO Test
const api = "https://starraidle-backend.com/api/updates";

const getUpdates = async () => {
  const updates = await axios.get(api);
  try {
    return updates.data;
  } catch (error) {
    console.error(error);
  }
};

export default getUpdates;
