<template>
  <div
    class="overflow-x-auto text-xs sm:text-sm md:text-base lg:text-lg xl:text-sm  md:w-11/12 xl:w-11/12 2xl:w-1/2 flex flex-col mt-3 text-center mx-auto md:font-semibold cursor-default"
    :class="width === true ? 'w-full text-xs font-medium' : 'w-11/12 font-semibold'">
    <div class="flex flex-row md:gap-1 text-center mx-auto mb-2 text-white" :class="[width === true ? 'gap-0.5' : 'gap-1']">
      <TitleTooltipComponent
        v-for="(title, index) in titles"
        :key="index"
        :title="title"
        :fullWidth="width" />
    </div>
    <div
      class="flex flex-row md:gap-1 text-center mx-auto mb-2 md:mb-4 leading-4 text-white tracking-tighter sm:tracking-normal"
      :class="width === true ? 'gap-0.5' : 'gap-1'"
      v-for="character in props.guessedCharacters"
      :key="character.id">
      <div
        class="md:w-24 md:h-24 lg:w-32 lg:h-32 xl:w-28 xl:h-28 rounded image-container test-class"
        :class="[
          width === true ? 'w-14 h-14' : 'w-20 h-20',
          {
            // 'test-class': props.newInsertComponent === true
            // 'visible': isVisible === true
          }
        ]">
        <img
          :alt="character.name"
          :src="character.image"
          style="width: 100%; height: auto"
          class="border border-slate-400 mx-auto rounded image z-40" />
        <div class="middle">
          <div class="text rounded-md py-0.5 px-2 z-50">{{ character.name }}</div>
        </div>
      </div>
      <div
        class="md:w-24 md:h-24 lg:w-32 lg:h-32 xl:w-28 xl:h-28 rounded p-0.5 line border border-slate-400 test-class"
        :class="[
          character.rarity === correctCharacter.rarity
            ? 'correct'
            : 'incorrect',
            width === true ? 'w-14 h-14' : 'w-20 h-20',
            {
              // 'test-class': props.newInsertComponent === true
              // 'visible': isVisible === true
            }
        ]">
        {{ character.rarity }}
      </div>
      <div
        class="md:w-24 md:h-24 lg:w-32 lg:h-32 xl:w-28 xl:h-28 rounded p-0.5 line border border-slate-400 test-class"
        :class="[
          character.path === correctCharacter.path
            ? 'correct'
            : 'incorrect',
            width === true ? 'w-14 h-14' : 'w-20 h-20',
            {
              'font-clamp-tighter': (character.path === 'Preservation' && width === true) || (character.path === 'Destruction' && width === true)
            }
        ]">
        {{ character.path }}
      </div>
      <div
        class="md:w-24 md:h-24 lg:w-32 lg:h-32 xl:w-28 xl:h-28 rounded p-0.5 line border border-slate-400 test-class"
        :class="[
          character.type === correctCharacter.type
            ? 'correct'
            : 'incorrect',
            width === true ? 'w-14 h-14' : 'w-20 h-20',
            {
              // 'test-class': props.newInsertComponent === true
              // 'visible': isVisible === true
            }
        ]">
        {{ character.type }}
      </div>
      <div
        class="md:w-24 md:h-24 lg:w-32 lg:h-32 xl:w-28 xl:h-28 rounded p-0.5 line border border-slate-400 test-class"
        :class="[
          character.species === correctCharacter.species
            ? 'correct'
            : 'incorrect',
            width === true ? 'w-14 h-14' : 'w-20 h-20',
            {
              // 'test-class': props.newInsertComponent === true
              // 'visible': isVisible === true
            }
        ]">
        {{ character.species }}
      </div>
      <div
        class="md:w-24 md:h-24 lg:w-32 lg:h-32 xl:w-28 xl:h-28 rounded p-0.5 line border border-slate-400 test-class"
        :class="[
          character?.gender === correctCharacter.gender
            ? 'correct'
            : 'incorrect',
            width === true ? 'w-14 h-14' : 'w-20 h-20',
            {
              // 'test-class': props.newInsertComponent === true
              // 'visible': isVisible === true
            }
        ]">
        {{ character.gender }}
      </div>
      <div
        class="md:w-24 md:h-24 lg:w-32 lg:h-32 xl:w-28 xl:h-28 rounded p-0.5 line border border-slate-400 test-class md:font-clamp"
        :class="[{
          'correct': arraysMatch(
            character.world,
            correctCharacter.world
          ),
          'bg-gradient-to-r from-amber-600 via-amber-700 to-amber-600 hover:from-amber-500 hover:to-amber-500': arraysPartialMatch(
            character.world,
            correctCharacter.world
          ),
          'incorrect': arraysNoMatch(
            character.world,
            correctCharacter.world
          ),
          'leading-none md:leading-4': character.world.length > 1,
          // 'test-class': props.animation === true,
          // 'visible': isVisible === true
          'tighter-text': character.world.length > 1 && width === true
        },
          width === true ? 'w-14 h-14' : 'w-20 h-20',
        ]">
        <span class="inline">
          <span v-for="(world, index) in character.world" :key="index">
            {{ world
            }}<span v-if="index < character.world.length - 1">,<br /></span>
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import TitleTooltipComponent from "@/components/UI/TitleTooltipComponent.vue";
import { watch, onMounted, ref, defineEmits } from "vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  guessedCharacters: Array,
  correctCharacter: Object,
  animation: Boolean,
  fullWidth: Boolean,
  visible: Boolean,
});

const width = ref(false);
const animationFinished = defineEmits(['animationFinished']);

onMounted(() => {
  width.value = props.fullWidth;
  addAnimation();

});

watch(
  () => props.guessedCharacters.length,
  (newLength, oldLength) => {
    if (newLength !== oldLength) {
      setTimeout(() => {
        addAnimation();
      }, 0); // Invoke the function to add the classes to divs with a delay
      animationFinished('animationFinished', true);
    }
  }
);

watch(
  () => props.fullWidth,
  (newVal) => {
    width.value = newVal;
    const divs = Array.from(document.getElementsByClassName("test-class"));
    divs.forEach((div) => {
      div.style.opacity = 1;
      setTimeout(() => {
        div.classList.add("visible");
        if (newVal === true) {
          div.classList.add("font-clamp");
        }
      })
    });
  }
);

const addAnimation = () => {
  const divs = Array.from(document.getElementsByClassName("test-class"));
  const filteredDivs = divs.filter((div) => !div.classList.contains("visible"));
  filteredDivs.forEach((div, index) => {
    const onAnimationEnd = () => {
      div.removeEventListener('animationend', onAnimationEnd);
    };

    if (props.animation) {
      setTimeout(() => {
        div.classList.add("visible");
        div.classList.add("animate__animated", "animate__flipInX");
        div.addEventListener('animationend', onAnimationEnd);
      }, index * 350);
    } else {
      div.classList.add("visible");
    }
    onAnimationEnd();
  });
};

const titles = [
  {
    name: "Character",
    description: "Arlan, Asta, etc...",
  },
  {
    name: "Rarity",
    description: "4 star or 5 star",
  },
  {
    name: "Path",
    description: "Destruction, Preservation, etc...",
  },
  {
    name: "Type",
    description: "Lightning, Quantum, etc...",
  },
  {
    name: "Species",
    description: "Human, Xianzhou Native, etc...",
  },
  {
    name: "Gender",
    description: "Presumed gender: Male, Female, or Other",
  },
  {
    name: "World(s)",
    description: "Herta Space Station, The Xianzhou Luofu, etc...",
  },
];

const arraysMatch = (array1, array2) => {
  return (
    array1?.length === array2?.length &&
    array1?.every((value, index) => value === array2[index])
  );
};

const arraysPartialMatch = (array1, array2) => {
  return (
    array1?.some((item) => array2?.includes(item)) &&
    !arraysMatch(array1, array2)
  );
};

const arraysNoMatch = (array1, array2) => {
  return !arraysPartialMatch(array1, array2) && !arraysMatch(array1, array2);
};
</script>

<style scoped>
.line {
  display: flex;
  justify-content: center;
  align-items: center;
}

.font-clamp {
  font-size: clamp(11px, 1.5vw, 15px) !important;
}

.font-clamp-tighter {
  font-size: clamp(10px, 1.5vw, 15px) !important;
}

.tighter-text {
  @media only screen and (max-width: 600px) {
    font-size: clamp(9px, 1.5vw, 15px) !important;
  }
}

.image-container {
  position: relative;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.image-container:hover .image {
  opacity: 0.3;
}

.image-container:hover .middle {
  opacity: 1;
}

.text {
  background-color: #343a38;
  color: white;
  border: 1px solid white;
  border-radius: 2px;
  font-size: 13px;
}

.test-class {
  opacity: 0;
  transition: opacity 1s;
  box-shadow: 2px 4px 2px rgba(0, 0, 0, 0.8);
}

.visible {
  opacity: 1 !important;
}

</style>
