import axios from "axios";

// Local
// const api = "http://192.168.0.32:3000/api/characters";

// Deploy test
// const api = 'https://starraidle-api.onrender.com/api/characters';

// DO Test
const api = "https://starraidle-backend.com/api/characters";

const getCharacters = async () => {
  const characters = await axios.get(api);
  try {
    return characters.data;
  } catch (error) {
    console.error(error);
  }
};

export default getCharacters;
