<template>
  <div class="text-white text-center">
    <div
      class="mb-4 mx-auto w-fit border-b flex gap-4 border-stone-600/60 pb-1">
      <div>
        <a href="https://ko-fi.com/nikodev" target="_blank" title="Buy me a kofi!"
          ><img
            src="../assets/kofi.png"
            alt="Kofi logo"
            style="width: 40px; height: auto"
        /></a>
      </div>
      <div
        @click="visible = true"
        v-tooltip.top="{
          value: `Information`,
          pt: {
            root: ({ context }) => ({
              class: [
                'absolute shadow-md',
                {
                  'py-0 px-1':
                    context?.right ||
                    context?.left ||
                    (!context?.right &&
                      !context?.left &&
                      !context?.top &&
                      !context?.bottom),
                  'py-1 px-0': context?.top || context?.bottom,
                },
              ],
            }),
            arrow: ({ context }) => ({
              class: [
                'absolute w-0 h-0 border-transparent border-solid',
                {
                  '-m-t-1 border-y-[0.25rem] border-r-[0.25rem] border-l-0 border-r-stone-600':
                    context?.right ||
                    (!context?.right &&
                      !context?.left &&
                      !context?.top &&
                      !context?.bottom),
                  '-m-t-1 border-y-[0.25rem] border-l-[0.25rem] border-r-0 border-l-stone-600':
                    context?.left,
                  '-m-l-1 border-x-[0.25rem] border-t-[0.25rem] border-b-0 border-t-stone-600':
                    context?.top,
                  '-m-l-1 border-x-[0.25rem] border-b-[0.25rem] border-t-0 border-b-stone-600':
                    context?.bottom,
                },
              ],
            }),
            text: {
              class:
                'text-sm p-3 bg-stone-600 text-white rounded-md whitespace-pre-line break-words border-2 border-amber-300/60',
            },
          },
        }"
        class="text-white/80 cursor-pointer hover:text-amber-300/80">
        <span
          class="pi pi-info-circle hover:text-amber-300/60 hover:cursor-pointer"
          style="font-size: 40px"></span>
      </div>
      <Dialog
        v-model:visible="visible"
        modal
        header="Updates"
        :style="{ width: '50rem' }"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
        :pt="{
          root: ({ state }) => ({
            class: [
              'rounded-lg shadow-lg border border-slate-500/40 max-w-[90%]',
              'max-h-[90%] transform scale-100',
              'm-0 w-[50vw]',
              {
                'transition-none transform-none !w-screen !h-screen !max-h-full !top-0 !left-0':
                  state.maximized,
              },
            ],
          }),
          header: {
            class: [
              'flex items-center justify-between shrink-0',
              'bg-zinc-900 text-white/80 border-t-0  rounded-tl-lg rounded-tr-lg p-6',
            ],
          },
          headerTitle: {
            class: 'font-bold text-lg',
          },
          headerIcons: {
            class: 'flex items-center',
          },
          closeButton: {
            class: [
              'flex items-center justify-center overflow-hidden relative',
              'w-8 h-8 text-gray-500 border-0 bg-transparent rounded-full transition duration-200 ease-in-out mr-2 last:mr-0',
              'hover:text-gray-700 hover:border-transparent hover:bg-gray-200',
              'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]', // focus
              'dark:hover:text-white/80 dark:hover:border-transparent dark:hover:bg-gray-800/80 dark:focus:shadow-[inset_0_0_0_0.2rem_rgba(147,197,253,0.5)]',
            ],
          },
          closeButtonIcon: {
            class: 'w-4 h-4 inline-block',
          },
          content: ({ state, instance }) => ({
            class: [
              'overflow-y-auto',
              'bg-zinc-900 text-white/80 px-6 pb-8 pt-0',
              {
                grow: state.maximized,
              },
              {
                'rounded-bl-lg rounded-br-lg': !instance.$slots.footer,
              },
            ],
          }),
          footer: {
            class: [
              'flex gap-2 shrink-0 justify-end align-center',
              'border-t-0 bg-white text-gray-700 px-6 pb-6 text-right rounded-b-lg',
              'dark:bg-gray-900  dark:text-white/80',
            ],
          },
          mask: ({ props }) => ({
            class: ['transition duration-200', { 'bg-black/40': props.modal }],
          }),
          transition: ({ props }) => {
            return props.position === 'top'
              ? {
                  enterFromClass:
                    'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0',
                  enterActiveClass: 'transition-all duration-200 ease-out',
                  leaveActiveClass: 'transition-all duration-200 ease-out',
                  leaveToClass:
                    'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0',
                }
              : props.position === 'bottom'
              ? {
                  enterFromClass: 'opacity-0 scale-75 translate-y-full',
                  enterActiveClass: 'transition-all duration-200 ease-out',
                  leaveActiveClass: 'transition-all duration-200 ease-out',
                  leaveToClass:
                    'opacity-0 scale-75 translate-x-0 translate-y-full translate-z-0',
                }
              : props.position === 'left' ||
                props.position === 'topleft' ||
                props.position === 'bottomleft'
              ? {
                  enterFromClass:
                    'opacity-0 scale-75 -translate-x-full translate-y-0 translate-z-0',
                  enterActiveClass: 'transition-all duration-200 ease-out',
                  leaveActiveClass: 'transition-all duration-200 ease-out',
                  leaveToClass:
                    'opacity-0 scale-75  -translate-x-full translate-y-0 translate-z-0',
                }
              : props.position === 'right' ||
                props.position === 'topright' ||
                props.position === 'bottomright'
              ? {
                  enterFromClass:
                    'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0',
                  enterActiveClass: 'transition-all duration-200 ease-out',
                  leaveActiveClass: 'transition-all duration-200 ease-out',
                  leaveToClass:
                    'opacity-0 scale-75 opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0',
                }
              : {
                  enterFromClass: 'opacity-0 scale-75',
                  enterActiveClass: 'transition-all duration-200 ease-out',
                  leaveActiveClass: 'transition-all duration-200 ease-out',
                  leaveToClass: 'opacity-0 scale-75',
                };
          },
        }">
        <template #header>
          <div class="border-b w-11/12">
            <span class="font-bold text-2xl white-space-nowrap"
              >INFORMATION</span
            >
          </div>
        </template>
        <div class="bg-zinc-700/80 border rounded p-2 mb-3">
          <div class="text-xl text-amber-300/70 font-semibold">Disclaimer</div>
          <div class="text-sm mt-2 italic">
            StarRaidle is a fan-made game created solely for
            entertainment purposes. This game is not endorsed by or affiliated
            with miHoYo, the developer of Honkai Star Rail. All assets,
            characters, and content related to Honkai Star Rail used within this
            game are the intellectual property of miHoYo.
          </div>
        </div>
        <div class="bg-zinc-700/80 border rounded p-2">
          <div class="text-xl text-amber-300/70 font-semibold">About</div>
          <div>
            Welcome to
            <span class="text-amber-300/50 italic font-semibold"
              >StarRaidle</span
            >
            - your daily character guessing game!
          </div>
          <div class="text-sm mt-2">
            Greatly inspired by games like:
            <a
              class="text-blue-500 font-semibold"
              href="https://www.nytimes.com/games/wordle/index.html"
              target="_blank"
              >Wordle</a
            >,
            <a
              class="text-blue-500 font-semibold"
              href="https://loldle.net/"
              target="_blank"
              >Loldle</a
            >
            and similiar games on that theme.
          </div>
          <div class="text-sm">
            Most of the information about characters, including pictures, is
            from:
            <a
              class="text-blue-500 font-semibold"
              href="https://honkai-star-rail.fandom.com/wiki/Honkai:_Star_Rail_Wiki"
              target="_blank"
              >Honkai Starrail Fandom Wiki</a
            >
          </div>
          <div class="text-sm">
            Background image source:
            <a
              class="text-blue-500 font-semibold"
              target="_blank"
              href="https://honkai-star-rail.fandom.com/wiki/Character?file=Second_Closed_Beta_Wallpaper.png"
              >URL</a
            >
          </div>
          <div class="text-sm">
            Icons:
            <ul class="list-disc list-inside">
              <li>
                <a
                  target="_blank"
                  href="https://icons8.com/icon/98023/expand"
                  class="text-blue-500 font-semibold"
                  >Expand</a
                >
                icon by
                <a
                  target="_blank"
                  href="https://icons8.com"
                  class="text-blue-500 font-semibold"
                  >Icons8</a
                >
              </li>
            </ul>
          </div>
          <div class="text-sm mt-1">
            This website uses cookies to collect daily wins data and show
            ads.<br />
            More information can be found in the
            <a
              class="text-blue-500 font-semibold"
              href="/privacy_policy.html"
              target="_blank"
              >Privacy Policy</a
            >
          </div>
        </div>
      </Dialog>
    </div>
    <div class="text-base md:text-lg lg:text-xl xl:text-base">
      Made with <span class="pi pi-heart-fill text-rose-500/80"></span> by
      <a href="https://ko-fi.com/nikodev" target="_blank" class="text-blue-500 font-semibold">Niko</a>
    </div>
    <div class="text-zinc-500 text-sm md:text-base lg:text-lg xl:text-sm">
      StarRaidle - &copy; {{ year }}
    </div>
    <div class="text-zinc-600 font-mono text-xs md:text-sm lg:text-base xl:text-xs">
      {{ props.buildInfo.build }}
    </div>
  </div>
</template>
<script setup>
import Dialog from "primevue/dialog";
import { ref } from "vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  buildInfo: Object,
});
const visible = ref(false);
const year = new Date().getFullYear();
</script>
