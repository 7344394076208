<template>
  <canvas id="my-canvas"></canvas>
  <div
    :class="fullWidth == true ? 'max-w-full sm:max-w-full' : 'max-w-xs mx-auto'"
    class="sm:container sm:mx-auto py-4 sm:max-w-full md:max-w-full overflow-hidden text-center">
    <HeaderComponent :wins="totalWins" />
    <div
      class="bg-zinc-800 w-fit p-4 mt-2 mb-6 mx-auto rounded-md border-2 border-amber-300/60">
      <h2 class="text-xl md:text-3xl xl:text-xl text-white font-semibold">
        Try to guess today's character!
      </h2>
      <p class="text-sm md:text-lg xl:text-sm text-white/60">
        Type any character name to begin.
      </p>
    </div>
    <div v-show="!isDisabled">
      <AutoComplete
        inputId="characters"
        v-if="characters && randomCharacter"
        v-model="value"
        :disabled="isDisabled"
        optionLabel="name"
        @change="handleSelect"
        placeholder="Search..."
        :fullWidth="fullWidth"
        :suggestions="filteredCharacters"
        @complete="search"
        forceSelection
        :pt="{
          root: ({ props }) => ({
            class: [
              'relative inline-flex w-5/6 sm:w-4/6 md:w-3/6 lg:w-2/6 xl:w-2/6 2xl:w-1/4 mx-auto',
              {
                'opacity-60 select-none pointer-events-none cursor-default':
                  props.disabled,
              },
              { 'w-full': props.multiple },
            ],
          }),
          container: {
            class: [
              'm-0 list-none cursor-text overflow-hidden flex items-center flex-wrap w-full',
              'px-3 py-2 gap-2',
              'font-sans text-base text-white/90 bg-zinc-800 border border-stone-200/40  transition duration-200 ease-in-out appearance-none rounded-md',
              'focus:outline-offset-0 hover:border-stone-200 focus:outline-none focus:shadow-[0_0_0_0.2rem_rgba(245,245,245,0.5)]',
            ],
          },
          inputtoken: {
            class: ['py-0.375rem px-0', 'flex-1 inline-flex'],
          },
          input: ({ props }) => ({
            class: [
              'm-0 w-full',
              ' transition-colors duration-200 appearance-none rounded-md',
              { 'rounded-tr-none rounded-br-none': props.dropdown },
              {
                'font-sans text-base md:text-2xl lg:text-base text-white/90 bg-zinc-800 p-3 border-2 border-stone-200/40 focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(245,245,245,0.5)] hover:border-stone-200 focus:outline-none':
                  !props.multiple,
                'font-sans text-base md:text-2xl lg:text-base text-white/90 border-0 outline-none bg-transparent m-0 p-0 shadow-none rounded-none w-full':
                  props.multiple,
              },
            ],
          }),
          token: {
            class: [
              'py-1 px-2 mr-2 bg-gray-700 text-white/80 rounded-full',
              'cursor-default inline-flex items-center',
            ],
          },
          emptyMessage: {
            class: 'px-2',
          },
          dropdownbutton: {
            root: 'rounded-tl-none rounded-bl-none',
          },
          panel: {
            class: [
              'bg-zinc-800 text-white/90 border-0 rounded-md shadow-lg',
              'max-h-[200px] overflow-auto',
            ],
          },
          list: 'py-3 list-none m-0 divide-y divide-zinc-400/60',
          item: ({ context }) => ({
            class: [
              'cursor-pointer font-medium overflow-hidden relative whitespace-nowrap',
              'm-0 p-3 border-0  transition-shadow duration-200 rounded-none',
              'text-white/90 hover:bg-stone-300/20',
              'hover:text-gray-200/60 hover:bg-stone-300/10',
              {
                'text-gray-700': !context.focused && !context.selected,
                'bg-stone-300/20 text-white/90':
                  context.focused && !context.selected,
                'bg-stone-400 text-white/90':
                  context.focused && context.selected,
                'bg-stone-300 text-white/90':
                  !context.focused && context.selected,
              },
            ],
          }),
          itemgroup: {
            class: [
              'm-0 p-3 text-white/80 bg-gray-900 font-bold',
              'cursor-auto',
            ],
          },
          transition: {
            enterFromClass: 'opacity-0 scale-75',
            enterActiveClass:
              'transition-transform transition-opacity duration-150 ease-in',
            leaveActiveClass: 'transition-opacity duration-150 ease-linear',
            leaveToClass: 'opacity-0',
          },
        }">
        <template #option="slotProps">
          <div class="flex align-options-center gap-2">
            <img
              :alt="slotProps.option.name"
              :src="slotProps.option.img"
              class="border border-neutral-200"
              style="width: 50px; height: auto" />
            <div class="self-center text-lg md:text-2xl lg:text-lg">
              {{ slotProps.option.name }}
            </div>
          </div>
        </template>
      </AutoComplete>
    </div>
    <div v-show="isDisabled">
      <div
        class="text-white font-extrabold text-4xl animate__animated animate__bounce">
        <span class="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] bg-gradient-to-tr from-amber-50 from-10% via-amber-400 to-90% to-amber-50 inline-block text-transparent bg-clip-text">CORRECT GUESS!</span>&#127942;
      </div>
      <div class="text-white/90 italic font-medium text-sm">"{{ randomCharacter.greeting }}"</div>
      <div class="text-sm text-neutral-400">
        Play again in
        <span class="font-semibold"
          >{{ hoursLeft }}:{{ minutesLeft }}:{{ secondsLeft }}</span
        >
      </div>
    </div>
    <GuessedCharactersComponent
      :guessedCharacters="guessedCharacters"
      :correctCharacter="randomCharacter"
      :animation="animation"
      :fullWidth="fullWidth"
      :visible="visible"
      v-show="guessedCharacters[0]" />
    <div v-if="!guessedCharacters.length < 1" class="flex flex-col gap-2 md:hidden text-zinc-400 text-xs mt-2">
      <div>Hold and Scroll</div>
      <button
        @click="changeWidth"
        class="bg-zinc-800 border border-amber-300/60 rounded p-1 w-fit mx-auto cursor-pointer">
        <img v-if="fullWidth === true" src="@/assets/icons8-collapse-24.png" alt="Collapse icon">
        <img v-if="fullWidth === false" src="@/assets/icons8-expand-24.png" alt="Expand icon">
      </button>
    </div>
    <div class="mt-52 mx-auto">
      <div
        v-if="daysGuessed > 0"
        class="text-white text-sm sm:text-base md:text-lg lg:text-xl xl:text-base font-semibold border-b border-stone-300/50 w-fit mx-auto">
        Yesterday's character was #{{ daysGuessed }}
        <span class="text-amber-300/80">{{ yesterdaysCharacter.name }}</span>
      </div>
    </div>
    <div class="mt-52">
      <FooterComponent :buildInfo="buildInfo" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import AutoComplete from "primevue/autocomplete";
import getCharacters from "@/api/getCharacters.js";
import getRandomCharacter from "@/api/getRandomCharacter.js";
import GuessedCharactersComponent from "./components/GuessedCharactersComponent.vue";
import HeaderComponent from "./components/HeaderComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";
import Cookies from "js-cookie";
import getCharacterById from "@/api/getCharacterById.js";
import ConfettiGenerator from "confetti-js";
import getUpdates from "@/api/getUpdates";

const value = ref();
const filteredCharacters = ref([]);
const characters = ref([]);
const randomCharacter = ref({});
const isDisabled = ref();
const guessedCharacters = ref([]);
const animation = ref(true);
const daysGuessed = ref(0);
const yesterdaysCharacter = ref();
const totalWins = ref(0);
const userDaysGuessed = ref(0);

const secondsLeft = ref();
const minutesLeft = ref();
const hoursLeft = ref();

const fullWidth = ref(false);
const visible = ref(false);
const buildInfo = ref({});

onMounted(async () => {
  const fetchedCharacters = await getCharacters();
  const fetchedRandomCharacter = await getRandomCharacter();
  
  const lastDayGuessed = getCookie("lastDayGuessed");
  if(lastDayGuessed) {
    const currentDate = new Date();   // Creates a new Date object for the current date and time
    const todaysDate = formatDate(currentDate);
    if(isMoreThanOneDayDiff(todaysDate, lastDayGuessed)) {
      totalWins.value = 0;
      setCookie("totalWins", 0);
    } else {
      const totalWinsCookie = getCookie("totalWins");
      if (totalWinsCookie) {
        totalWins.value = Number(totalWinsCookie);
      }
    }
  } else {
    const totalWinsCookie = getCookie("totalWins");
    if (totalWinsCookie) {
      totalWins.value = Number(totalWinsCookie);
    }
  }

  const fetchedBuild = await getUpdates();
  buildInfo.value = fetchedBuild.info;
  characters.value = fetchedCharacters;
  const playable = getCookie("playable");
  if (playable) {
    isDisabled.value = Boolean(playable);
    timeRemaining();
    setInterval(() => {
      timeRemaining();
    }, 1000);
  } else {
    isDisabled.value = false;
  }
  preloadImages();
  
  async function resetGame() {
    const newRandomChar = await getRandomCharacter();
    randomCharacter.value = newRandomChar;
    daysGuessed.value = randomCharacter.value.days;

    const newFetchedCharacters = await getCharacters();
    characters.value = newFetchedCharacters;
    
    animation.value = true;
    guessedCharacters.value = [];
    
    if (!isDisabled.value) {
      totalWins.value = 0;
      removeCookie("totalWins");
    }
    isDisabled.value = false;
    removeCookie("playable");
    setCookie("randomChar", newRandomChar.id);
    removeCookie("guessedCharacters");
    
    fetchAtMidnightUTC();
    getYesterdaysCharacter(newRandomChar);
  }
  
  function fetchAtMidnightUTC() {
    const now = new Date();
    const midnightUTC = new Date(now);
    midnightUTC.setUTCHours(24, 0, 0o1, 0); // Set to next midnight UTC
    // midnightUTC.setUTCHours(2, 55, 0o1, 0); // Set to next midnight UTC
    preloadImages();
    let timeUntilMidnightUTC = midnightUTC.getTime() - now.getTime();
    if (timeUntilMidnightUTC < 0) {
      timeUntilMidnightUTC += 24 * 60 * 60 * 1000; // Add 24 hours for the next day's midnight
    }
    setTimeout(() => {
      resetGame(); // Fetch at midnight UTC
      setInterval(resetGame, 24 * 60 * 60 * 1000); // Daily interval
    }, timeUntilMidnightUTC);
  }
  
  fetchAtMidnightUTC();
  const randomChar = getCookie("randomChar");
  const userDaysGuessedCookie = getCookie("userDaysGuessed");
  if (userDaysGuessedCookie) {
    userDaysGuessed.value = Number(userDaysGuessedCookie);
  }
  if (!randomChar) {
    randomCharacter.value = fetchedRandomCharacter;
    daysGuessed.value = fetchedRandomCharacter.days;
    if (fetchedRandomCharacter.yesterday) {
      yesterdaysCharacter.value = fetchedRandomCharacter.yesterday;
      getYesterdaysCharacter(fetchedRandomCharacter);
    }
    setCookie("randomChar", randomCharacter.value.id);
  } else if(Number(randomChar) !== fetchedRandomCharacter.id) {
    randomCharacter.value = fetchedRandomCharacter;
    daysGuessed.value = fetchedRandomCharacter.days;
    if (fetchedRandomCharacter.yesterday) {
      yesterdaysCharacter.value = fetchedRandomCharacter.yesterday;
      getYesterdaysCharacter(fetchedRandomCharacter);
    }
    setCookie("randomChar", randomCharacter.value.id);
    removeCookie("playable");
    removeCookie("guessedCharacters");
    isDisabled.value = false;
  }
  else if(fetchedRandomCharacter.days !== userDaysGuessed.value && isDisabled.value) {
    randomCharacter.value = fetchedRandomCharacter;
    daysGuessed.value = fetchedRandomCharacter.days;
    if (fetchedRandomCharacter.yesterday) {
      yesterdaysCharacter.value = fetchedRandomCharacter.yesterday;
      getYesterdaysCharacter(fetchedRandomCharacter);
    }
    setCookie("randomChar", randomCharacter.value.id);
    removeCookie("playable");
    removeCookie("guessedCharacters");
    isDisabled.value = false;
  } 
  else {
    // const characterId = Number(randomChar);
    const character = fetchedRandomCharacter;
    randomCharacter.value = character;
    daysGuessed.value = randomCharacter.value.days;
    if (fetchedRandomCharacter.yesterday) {
      yesterdaysCharacter.value = fetchedRandomCharacter.yesterday;
    }
  }
  
  const guessedCharactersCookie = getCookie("guessedCharacters");
  if (guessedCharactersCookie) {
    animation.value = false;
    const arrayOfNumbers = guessedCharactersCookie.split(",").map(Number);
    const promises = arrayOfNumbers.map(async (num) => {
      removeFromCharacters(num);
      return await getCharacterById(num);
    });
    const resolvedCharacters = await Promise.all(promises);
    guessedCharacters.value = resolvedCharacters;
  }
});

function isMoreThanOneDayDiff(dateStr1, dateStr2) {
    // Parse the dates from "DD.MM.YYYY" format
    const parseDate = (dateStr) => {
        const [day, month, year] = dateStr.split('.');
        return new Date(year, month - 1, day);
    };

    // Create date objects
    const date1 = parseDate(dateStr1);
    const date2 = parseDate(dateStr2);

    // Calculate the difference in milliseconds
    const diff = Math.abs(date2 - date1);

    // Convert the difference to days
    const daysDiff = diff / (1000 * 60 * 60 * 24);

    // Check if the difference is more than one day
    return daysDiff > 1;
  }

const preloadImages = () => {
  characters.value.forEach((character) => {
    const img = new Image();
    img.src = character.image;
    character.img = img.src;
    
  })
}
function changeWidth() {
  animation.value = false;
  visible.value = true;
  if (fullWidth.value == true) {
    fullWidth.value = false;
  } else if (fullWidth.value == false) {
    fullWidth.value = true;
  }
}

function getYesterdaysCharacter(crr) {
  yesterdaysCharacter.value = crr.yesterday;
}

function getCookie(name) {
  try {
    const cookie = Cookies.get(btoa(name));
    if (cookie) {
      return atob(cookie);
    }
  } catch (error) {
    console.error(error);
  }
}

function setCookie(name, value) {
  const cookieName = btoa(name);
  const cookieValue = btoa(value);
  Cookies.set(cookieName, cookieValue, { expires: 365 });
}

function removeCookie(name) {
  const cookieName = btoa(name);
  Cookies.remove(cookieName);
}

function timeRemaining() {
  const now = new Date();
  const midnightUTC = new Date(now);
  midnightUTC.setUTCHours(24, 0, 0o1, 0); // Set to next midnight UTC

  let timeUntilMidnightUTC = midnightUTC.getTime() - now.getTime();
  if (timeUntilMidnightUTC < 0) {
    timeUntilMidnightUTC += 24 * 60 * 60 * 1000; // Add 24 hours for the next day's midnight
  }
  const secondsRemaining = Math.floor(timeUntilMidnightUTC / 1000);

  let hours = Math.floor(secondsRemaining / 3600);
  let minutes = Math.floor((secondsRemaining % 3600) / 60);
  let seconds = secondsRemaining % 60;

  // Add 0 if time is less than 10
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  if (minutes < 10) {
    minutes = "0" + minutes;
  }

  if (hours < 10) {
    hours = "0" + hours;
  }

  // Store values
  secondsLeft.value = seconds;
  minutesLeft.value = minutes;
  hoursLeft.value = hours;
}

const search = (event) => {
  filteredCharacters.value = characters.value.filter((char) =>
    char.name.toLowerCase().startsWith(event.query.toLowerCase())
  );
};

const handleSelect = (event) => {
  if (event.value && event.value.name) {
    animation.value = true;
    visible.value = false;
    value.value = event.value;
    guessedCharacters.value.unshift(event.value);
    const charArr = guessedCharacters.value.map((char) => char.id.toString());
    setCookie("guessedCharacters", charArr);
    checkCharacter();
    removeFromCharacters(event.value.id);
  }
};

function formatDate(date) {
        const day = date.getDate();          // Gets the day of the month (1-31)
        const month = date.getMonth() + 1;  // Gets the month (0-11) and adds 1 to adjust for zero-index
        const year = date.getFullYear();    // Gets the full year

        // Pad the day and month with zeros if they are less than 10
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;

        // Return the formatted date string
        return `${formattedDay}.${formattedMonth}.${year}`;
    }

// Check if the character is correct
const checkCharacter = async () => {
  // Check if id of guessed character is same as random character id
  if (value.value.id === randomCharacter.value.id) {
      value.value = "";
      setTimeout(() => {
        isDisabled.value = true; // Disable the game
        const confettiSettings = {
        "target":"my-canvas",
        "max":"450",
        "size":"1.3",
        "animate":true,
        "props":[
          "circle",
          "square",
          "triangle",
          "line"
        ],
        "colors":[[165,104,246],[230,61,135],[0,199,228],[253,214,126]],
        "clock":"28",
        "rotate":true,
        "start_from_edge":true,
        "respawn":false
      }
      const confetti = new ConfettiGenerator(confettiSettings);
      confetti.render();
      setTimeout(() => confetti.clear(), 15000);
      totalWins.value++; // Increase daily wins by 1
      setCookie("totalWins", totalWins.value); // Set daily wins in cookie
    }, 2400)

    setCookie("playable", true); // Set cookie to disable the game
    setCookie("randomChar", randomCharacter.value.id); // Set cookie of guessed characters


    const currentDate = new Date();   // Creates a new Date object for the current date and time
    const lastDayGuessed = formatDate(currentDate);
    setCookie("lastDayGuessed", lastDayGuessed)
    setCookie("userDaysGuessed", daysGuessed.value);
    // setCookie("daysGuessued", daysGuessed.value + 1)
    timeRemaining(); // Check the remaining time until game reset

    // document.querySelector('.celebration').classList.add('visible');
    // Check remaining time every second
    setInterval(() => {
      timeRemaining();
    }, 1000);
  } else {
    value.value = "";
  }
};

// Get CHARACTER id and remove it from characters Array
const removeFromCharacters = (selectedId) => {
  characters.value = characters.value.filter((char) => char.id !== selectedId);
};
</script>
